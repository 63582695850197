import React from "react"
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { StaticImage } from "gatsby-plugin-image";
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';

const Team = () => {

function collapse(comp){
    let el = comp.target;
    //grabbing bio
    var content = el.parentElement.nextElementSibling;
    //"extender" for 2nd column team members; extends the page so the bio doesnt overlap the footer
    var extender = document.getElementsByClassName("extend")[0];

    //reset extender
    if(extender.style.display === "block"){
	extender.style.display = "none";
    }
    //close bio if already open and reset text decoration on a tag
    if (content.style.display === "block") {
      content.style.display = "none";
      el.style.color = "black";
      el.innerHTML = "View Bio";
      el.style.fontWeight = "normal";
    } else { //open bio if closed and change a tag to be a "close bio" link
      content.style.display = "block";
      el.style.color = "#61c8ff";
      el.innerHTML = "Close Bio";
      el.style.fontWeight = "bold";
      
      //extend extender if one of the 2nd row team members
      var coll = document.getElementsByClassName("team-member__link");
      for(var j = 0; j < coll.length; j++){
	if(coll[j] == el && j > 4){
		document.getElementsByClassName("extend")[0].style.display = "block";
	}
	//reset all other team member blocks (useful if someone clicks on another bio while having one already open)
	if(coll[j] != el){
		coll[j].parentElement.nextElementSibling.style.display = "none";
	        coll[j].style.color = "black";
	        coll[j].innerHTML = "View Bio";
  	        coll[j].style.fontWeight = "normal";
	}
      }
    }
}



    return (
        <Layout>
            <Seo title='Our Team' />
            <section className="team-section">
                <div class="team-container">
                    <h1 className="uppercase">Our Team</h1>
		    <div className="pete-container">
			<div className="pete-image">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/pete.png" alt="Pete Millington" />
                            </div>
                            <h4 className="team-member__name h4">Pete Millington</h4>
                            <p className="team-member__position">CEO</p>
			</div>
			<div className="pete-bio">
  			    	<p>Pete Millington has spent over 25 years working in quantitative finance, and founded Scientific Financial Systems in 2016 to advance the state 
				of the art of data science, big data and machine learning in asset management. His financial career began in 1994 when he was hired as a 
				quantitative equity analyst at Fidelity Investments. At Fidelity, Pete built proprietary stock-selection models and customized portfolio optimization 
				solutions for the US and Canadian equity markets. He managed a Canadian long-only strategy using these alpha models producing significant active outperformance.
				In 2004, Pete was promoted to Fidelity’s Director of Quantitative Equity Research & Strategies and served in this role for the following six years. 
				In 2011, Pete co-founded Haber Trilix Advisors as President, Partner, and Director of Research.
				Pete holds a Master’s in Aerospace Engineering from MIT in Cambridge, MA and an undergraduate degree in Aerospace Engineering from Texas A&M University. 
				Pete has been a Chartered Financial Analyst (CFA) since 1999. Prior to his career in finance, Pete worked for four years as a research scientist at 
				Draper Laboratory in Cambridge, MA investigating novel neural network architectures for advanced control system designs as well as supporting Draper’s NASA Space 
				Shuttle in-flight activities in Houston.</p>
			</div>
		    </div>
                    <div className="team-grid">
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/anne.png" alt="Anne Millington" />
                            </div>
                            <h4 className="team-member__name h4">Anne Millington</h4>
                            <p className="team-member__position">CAO</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
                        </div>
			    <div className="bio">
				<h6>Anne Millington</h6>
  			    	<p>As Chief Administrative Officer at Scientific Financial Systems (SFS), Anne is responsible for managing the day-to-day operations of a company and is 
				in charge of specific departments, including such as finance, Human Resources, and sales. Anne has an AB in Economics from Harvard College, and has worked in a 
				variety of financial firms both on the buy side and the sell side. In her life outside of the financial world, she serves in a fulfilling role as a per 
				diem hospital chaplain.</p>
			    </div>
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/marc.png" alt="Marc Lowenthal" />
                            </div>
                            <h4 className="team-member__name">Marc Lowenthal</h4>
                            <p className="team-member__position">Chief Product Officer</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
			</div>
                            <div className="bio">
				<h6>Marc Lowenthal</h6>
                                <p>With nearly 40 years in financial services and 30 of those in quantitative finance, Marc has re-partnered with Pete Millington for the third time. 
				Marc’s work history includes tenures leading Quantitative Analytics teams at Fidelity Investments and Acadian Asset Management, and in fixed income at Merrill Lynch. 
				Marc’s education background includes dual master’s degrees in Finance and Information Systems from Pace University, and undergraduate degrees in Information Systems 
				and Accounting from Syracuse.</p>
                            </div>
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/parker.png" alt="Parker Johnson" />
                            </div>
                            <h4 className="team-member__name">Parker Johnson</h4>
                            <p className="team-member__position">Senior Software Engineer</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
                        </div>
			    <div className="bio">
				<h6>Parker Johnson</h6>
                                <p>Graduated from Creighton University with two Computer Science degrees and is completing a master's degree in Web Application Development through Boston University. 
				Over the past 3+ years, has built multiple web applications of increasing complexity. At SFS, Parker is a “full-stack” developer, working on many of Quotient’s 
				modules, and managing its deployment and operations.</p>
                            </div>
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/koi.png" alt="Koi Stephanos" />
                            </div>
                            <h4 className="team-member__name">Koi Stephanos</h4>
                            <p className="team-member__position">Senior Data Scientist</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
                        </div>
			    <div className="bio">
				<h6>Koi Stephanos</h6>
                                <p>After graduating with a master’s degree in applied computing from ETSU, Koi joined SFS as a Senior Data Scientist and is currently working on building 
				out the Quotient analytics framework. Koi has a background in full-stack development and technology consulting, and recently published a paper regarding 
				his thesis work on pattern mining with machine learning algorithms.</p>
                            </div>
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/john.png" alt="Jack Caspers" />
                            </div>
                            <h4 className="team-member__name">Jack Caspers</h4>
                            <p className="team-member__position">Senior Software Engineer</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
                        </div>
			    <div className="bio">
				<h6>Jack Caspers</h6>
                                <p>A graduate of the University of Minnesota with a BS in Computer Science and pursuing a master’s in Software Engineering. At SFS, Jack applies his 
				3+ years of broad proficiency in many front-end development tools to enhancing the user interfaces of Quotient.</p>
                            </div>
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/zach.png" alt="Zach Bunch" />
                            </div>
                            <h4 className="team-member__name">Zach Bunch</h4>
                            <p className="team-member__position">Software Engineer</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
                        </div>
			    <div className="bio">
				<h6>Zach Bunch</h6>
                                <p>After graduating with a Master’s in Computer Science from ETSU, Zack joined the Missile Defense Agency as a software engineer supporting multi-stage, 
				solid fuel boosters with an EKV payload to intercept and destroy targets in space. At SFS, Zack is working as a Dev-Ops Engineer supporting CI/CD efforts.</p>
                            </div>
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/edward.png" alt="Edward Best" />
                            </div>
                            <h4 className="team-member__name">Edward Best, CFA</h4>
                            <p className="team-member__position">Chief Research and Development Officer</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
                        </div>
			    <div className="bio">
				<h6>Edward Best, CFA</h6>
                                <p>Edward has worked in the investments industry for more than 28 years and 18+ years in portfolio management roles.  Throughout his career, 
				he has developed extensive propriety quantitative investment techniques including stock selection factors and models, custom portfolio construction 
				optimization, particularly integrated long-short, and custom risk models used for both optimization and custom portfolio attribution.  He has been 
				managing an equity long-short, market-neutral, low volatility strategy for over past 11+ years.  This strategy was launched in June 2010 with a 
				partner RIA.  In June 2016, Edward founded Best Capital Management, LLC to provide this strategy through his own investment firm.  While the 
				strategy has managed external clients’ assets in the past, the strategy currently manages only internal assets.
				Previously, Edward spent 12 years working in long-only equity portfolio management and analyst jobs.  Edward was an equity mutual fund manager 
				with Fidelity Research and Management for 3 years.  Edward worked for Trusco Capital Management, now part of Virtus Investments, for 9 years, with 
				his final role as an equity mutual fund manager.  Prior to Trusco, Edward worked at AIM Investments, now part of Invesco, as a Sr. Quantitative 
				Analytics Developer for 2 years developing a data warehouse of equity fundamental and technical data, and derived factors.  Edward started his 
				investments career at BARRA, now part of MSCI, including a last role in management, developing risk models and risk analytics for 3 years.  
				Edward obtained the Charter Financial Analyst professional designation in 1997.  He earned a Master in Industrial Administration (MBA) with 
				concentrations in finance and information technology from Carnegie Mellon University in 1993.  He completed his undergrad degree from University of 
				North Carolina at Chapel Hill in 1988 with Bachelors of Arts degree in Philosophy.</p>
                            </div>
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/greg.png" alt="Greg Niss" />
                            </div>
                            <h4 className="team-member__name h4">Greg Niss, CFA</h4>
                            <p className="team-member__position">Product Analyst</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
                        </div>
			    <div className="bio">
				<h6>Greg Niss, CFA</h6>
  			    	<p>Greg Niss brings over 20 years of investment management experience to this role at SFS. Greg’s career is grounded in analyst roles on the buy-side, sell-side and in 
				independent research. He has held analyst positions at UBS Global Asset management, CreditSights and Cowen and Company where he performed quantitative, 
				capital structure strategy and technology strategy research. Most recently, Greg has served as Partner and Consultant at NFS Consulting Group providing 
				high-level recruiting solutions for investment management institutions. Greg earned his Bachelor of Science degree in Mathematics from Fairfield University 
				and is a CFA Charterholder.</p>
			    </div>
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/colin.png" alt="Colin Longval" />
                            </div>
                            <h4 className="team-member__name">Colin Longval</h4>
                            <p className="team-member__position">Sales and Business Development Manager</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
			</div>
                            <div className="bio">
				<h6>Colin Longval</h6>
                                <p>Colin Longval is a senior enterprise software sales executive who has serviced a wide range of industry disciplines, including finance and investment 
				management, management consulting, information technology, and CPG/manufacturing. He has held positions at Quickbase, a provider of a no-code application 
				development platform, Operin, an investment operations automation company, Salesforce, the world's #1 CRM platform, and Refinitiv, a London Stock Exchange 
				company (previously named Thomson Reuters), the financial technology, data and expertise company.  Colin joins Scientific Financial Systems as the Sales and 
				Business Development Manager, and is focused on creating opportunities for our customers to leverage their data in more meaningful and insightful ways.</p>
                            </div>
                        <div className="team-member">
                            <div className="team-member__image-holder">
                                <StaticImage src="../images/about/george.png" alt="George Kastner" />
                            </div>
                            <h4 className="team-member__name">George Kastner</h4>
                            <p className="team-member__position">Strategic Advisor</p>
			    <button className="team-member__link" onClick={e => collapse(e)}>View Bio</button>
			</div>
                            <div className="bio">
				<h6>George Kastner</h6>
                                <p>Experienced Chief Executive Officer with a demonstrated history of working in the management consulting industry. Skilled in Business Planning, 
				Entrepreneurship, Strategic Planning, Business Development, organizational transformation, and Marketing Strategy. Strong business development professional 
				graduated from UNC Chapel Hill NC, Ph.D. Creative, innovative and a successful C-level executive coach.</p>
                            </div>
                    </div>
                </div>
		<div className="extend"></div>
            </section>
        </Layout>
    )
}

export default Team;
